import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selectorYear", "selectorStatus", "selectorClusters", "selectorChapters", "selectorSDGs", "tsa"]

    filterTsas() {
        this.tsaTargets.forEach(tsa => {
            let visible = true;
            visible = this.tsaVisibility(this.selectorYearTargets, tsa.getAttribute("data-year"), visible);
            visible = this.tsaVisibility(this.selectorStatusTargets, tsa.getAttribute("data-status"), visible);
            visible = this.tsaVisibility(this.selectorClustersTargets, JSON.parse(tsa.getAttribute("data-clusters")), visible);
            visible = this.tsaVisibility(this.selectorChaptersTargets, JSON.parse(tsa.getAttribute("data-chapters")), visible);
            visible = this.tsaVisibility(this.selectorSDGsTargets, JSON.parse(tsa.getAttribute("data-sdgs")), visible);

            visible ? tsa.classList.remove("hidden") : tsa.classList.add("hidden");
        });
    }

    tsaVisibility(selectorList, attribute, visible){        
        return visible ? this.checkFilter(this.getFilterValues(selectorList), attribute) : false;
    }

    getFilterValues(selectorList){
        return selectorList.filter((selector) => selector.checked).map(selector => selector.value);
    }

    checkFilter(filter, attribute) {
        return filter.length ? filter.some(a => attribute.includes(a)) : true;
    }
}