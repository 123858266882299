
        import * as module0 from './controllers/dropdown_controller.js';import * as module1 from './controllers/filter_controller.js';import * as module2 from './controllers/modal_controller.js';import * as module3 from './controllers/resource_tabs_controller.js';import * as module4 from './controllers/tabbed_nav_controller.js';import * as module5 from './controllers/virtual_tour_controller.js'
        const modules = {
            "./controllers/dropdown_controller.js": module0,
            "./controllers/filter_controller.js": module1,
            "./controllers/modal_controller.js": module2,
            "./controllers/resource_tabs_controller.js": module3,
            "./controllers/tabbed_nav_controller.js": module4,
            "./controllers/virtual_tour_controller.js": module5,
        };
        export default modules;
      