import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["modal"]

	open(event) {
		event.preventDefault();
		this.modalTarget.showModal();
		document.body.classList.add("overflow-hidden");
	}

	close(event) {
		event.preventDefault();
		this.modalTarget.close();
		document.body.classList.remove("overflow-hidden");
	}

	closeNestedModal(event) {
		event.preventDefault();
		this.modalTarget.close();
	}

	clickOutside(event) {
		if (event.target === this.modalTarget) {
			this.modalTarget.close()
			document.body.classList.remove('overflow-hidden')
		}
	}

	clickOutsideNestedModal(event) {
		if (event.target === this.modalTarget) {
			this.modalTarget.close()
		}
	}
}